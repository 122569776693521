import Axios from "axios";

export function generateRandomString(length = 32) {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars.charAt(randomIndex);
  }

  return result;
}

const ref = "admin";


export function AxiosLogger(
  axiosInstance,
  token
) {
  axiosInstance.interceptors.request.use(
    (config) => {
      const requestID = generateRandomString();
      const origin = window.location.origin;

      Axios.post("https://aiboo-logs-api.next.pede.ai", {
        requestID,
        ref,
        type: "request",
        origin: origin,
        baseURL: config.baseURL,
        url: config.url,
        method: config.method,
        data: config.data,
        token,
        timestamp: new Date().toISOString(),
      });

      return {
        ...config,
        headers: {
          ...config.headers,
          "x-request-id": requestID,
        },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      const requestID = response.config.headers["x-request-id"];
      const statusCode = response.data.status;
      const origin = window.location.origin;

      Axios.post("https://aiboo-logs-api.next.pede.ai", {
        requestID,
        ref,
        type: "response",
        origin,
        method: response?.config?.method,
        timestamp: new Date().toISOString(),
        status: statusCode,
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      });
      return response;
    },
    (error) => {
      const requestID = error?.response.config.headers["x-request-id"];
      const statusCode = error?.response.data.status;
      const origin = window.location.origin;

      Axios.post("https://aiboo-logs-api.next.pede.ai", {
        requestID,
        ref,
        type: "response-error",
        origin,
        method: error?.response?.config?.method,
        timestamp: new Date().toISOString(),
        status: statusCode,
        response: {
          status: error?.response.status,
          statusText: error?.response.statusText,
          error: JSON.parse(error?.response?.request?.response),
        },
      });

      if (!!error?.config?.errorHandle === false) {
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
}

export default AxiosLogger;
