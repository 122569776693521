import React from "react";

import * as S from "./styles";

export function Input({
  label,
  disabled,
  value,
  onChange,
  placeholder,
  size = "100%",
}) {
  return (
    <S.Container size={size}>
      <label>{label}</label>
      <input
        placeholder={placeholder}
        className="form-control"
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
    </S.Container>
  );
}
