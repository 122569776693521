import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  font-family: "Nunito";
  color: #333;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CloseIcon = styled.button`
    background-color: transparent;
    border: none;
`;

export const Body = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: "Nunito";
  margin-bottom: 44px;
  color: #333;
`;

export const Footer = styled.div`
  display: flex;
  gap: 44px;
  justify-content: flex-end;

  > button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    font-family: "Nunito";
    color: #333;

    &.success {
      color: #00B333;
    }

    &.danger {
      color: #FF3333;
    }
  }
`;