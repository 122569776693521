import React from "react";
import * as S from "./styles";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

function Pagination({ prevPage, nextPage, changePage, currentPage, maxPage }) {
  const startPage = Math.max(currentPage - 3, 1);
  const endPage = Math.min(maxPage, currentPage + 3);

  const pages = [...Array(endPage - startPage + 1)].map(
    (_, i) => startPage + i
  );

  if (!maxPage) {
    return null;
  }

  return (
    <S.Container colSpan={6}>
      <S.Button onClick={prevPage} disabled={currentPage <= 1}>
        <MdKeyboardArrowLeft size={18} color="#333" weight="bold" />
      </S.Button>

      {!pages.includes(1) && (
        <S.Button onClick={() => changePage(1)}>{1}</S.Button>
      )}

      {startPage > 1 && <span>...</span>}

      {pages.map((page) => (
        <S.Button
          key={page}
          $selected={page === currentPage}
          onClick={() => changePage(page)}
        >
          {page}
        </S.Button>
      ))}

      {endPage < maxPage && <span>...</span>}

      {!pages.includes(maxPage) && (
        <S.Button onClick={() => changePage(maxPage)}>{maxPage}</S.Button>
      )}

      <S.Button onClick={nextPage} disabled={currentPage >= maxPage}>
        <MdKeyboardArrowRight size={18} color="#333" weight="bold" />
      </S.Button>
    </S.Container>
  );
}

export default Pagination;
