import React, { useCallback } from "react";

import { Controller } from "react-hook-form";
import { debounce } from "lodash";

import { merchantService } from "services";

import { Input } from "components/Input";
import { CustomSelect, CustomTextArea, TabButtons } from "../";

import { getLongMonthAndYear, stringUtil } from "utils";
import { formatMerchantLabel } from "../../helpers";

import * as Styled from "./styles";

export const Form = ({
  register,
  watch,
  handleSubmit,
  errors,
  control,
  onSubmit,
  financialPeriods,
  setValue,
}) => {
  const [description, type] = watch(["description", "type"]);

  const getAsyncOptions = async (inputText) => {
    const candidate = inputText.toLowerCase();
    const byLabel = ({ label }) => label.toLowerCase().includes(candidate);

    const response = await merchantService.listByIdOrName({ param: inputText });

    const formattedResponse = response?.estabelecimentos?.map((merchant) => ({
      label: formatMerchantLabel(merchant),
      value: merchant.id_estabelecimento,
    }));

    return formattedResponse?.filter(byLabel);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options) => callback(options));
    }, 1000),
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Styled.FieldSet>
        <Controller
          name="merchant"
          control={control}
          rules={{ required: "O campo estabelecimento é obrigatório." }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              async
              loadOptions={loadOptions}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              noOptionsMessage={() => "Estabelecimento não encontrado"}
              loadingMessage={() => "Carregando..."}
              error={errors.merchant}
              label="Estabelecimento"
              placeholder="Procure por nome ou Id"
              size="lg"
            />
          )}
        />

        <Controller
          name="period"
          control={control}
          rules={{ required: "O campo período financeiro é obrigatório." }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              options={financialPeriods?.data?.map((period) => ({
                value: period,
                label: getLongMonthAndYear(new Date(period.starts_at)),
              }))}
              noOptionsMessage={() => "Período não encontrado"}
              error={errors.period}
              label="Período financeiro"
              placeholder=""
            />
          )}
        />

        <TabButtons value={type} onChange={setValue} />

        <Input
          {...register("value", {
            required: "O campo valor é obrigatório.",
            onChange: (event) => {
              const { value } = event.target;
              event.target.value = stringUtil.maskToRealCurrency(
                value,
                type === "remove"
              );
            },
          })}
          error={errors.value}
          isMandatory
          type="text"
          placeholder={type === "remove" ? "-R$ 0,00" : "R$ 0,00"}
          label="Valor"
          style={{ maxWidth: 190, marginBottom: 8 }}
        />

        <Controller
          name="description"
          control={control}
          rules={{ required: "O campo motivo é obrigatório." }}
          render={({ field }) => (
            <CustomTextArea
              {...field}
              error={errors.description}
              label="Descreva o motivo"
              characterCount={description}
              maxLength="200"
            />
          )}
        />
      </Styled.FieldSet>
    </form>
  );
};
