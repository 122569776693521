import React from "react";

import * as S from "./styles";

export function SearchButton(props) {
  return (
    <S.Container {...props}>
      <S.Icon />
      Pesquisar
    </S.Container>
  );
}

export default SearchButton;
