import React, { useState, useCallback } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { toNumber } from "vanilla-masker";

import * as S from "./styles";

import { Input } from "../../../../components/Input";
import Button from "../../../../components/Button";
import { Select } from "../../../../components/Select";

export function Filter({ onChange, options }) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [categoria, setCategoria] = useState({ value: "", label: "" });

  const onSubmit = useCallback(
    (data) => {
      onChange({ ...data, id_categoria: categoria.value });
    },
    [categoria, onChange]
  );

  const onResetFilter = () => {
    reset();
    setCategoria({ value: "", label: "" });
    onChange({});
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <Input
          type="text"
          label="Id"
          style={{ width: 60 }}
          {...register("id_produto")}
          onChange={(e) => setValue("id_produto", toNumber(e.target.value))}
        />
        <Input
          type="text"
          label="Código de barras"
          style={{ minWidth: 150 }}
          {...register("codigo_barras")}
          onChange={(e) => setValue("codigo_barras", toNumber(e.target.value))}
        />
        <Input
          type="text"
          label="Nome do produto"
          style={{ minWidth: 225 }}
          {...register("nome_produto")}
        />
        <Select
          options={options}
          label="Categoria"
          placeholder={""}
          value={categoria}
          onChange={setCategoria}
          // onChange={(e) => setValue("nome_categoria", e.label)}
        />
        <Button type="submit" variant="danger">
          <BiFilterAlt size={18} weight="bold" style={{ marginRight: 10 }} />
          Filtrar
        </Button>
        <Button variant="danger-ghost" onClick={onResetFilter}>
          Limpar filtros
        </Button>
      </S.Container>
    </form>
  );
}

export default Filter;
