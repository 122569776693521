import React, { forwardRef } from "react";

import * as Styled from "./styles";

const CustomTextAreaComponent = (
  { label, characterCount, maxLength, error, isMandatory = true, ...rest },
  ref
) => {
  return (
    <>
      <Styled.Container error={error}>
        <Styled.Label>
          {label}
          {isMandatory && <span>*</span>}
        </Styled.Label>

        <textarea ref={ref} maxLength={maxLength} {...rest} />

        <small>
          {characterCount?.length}/{maxLength}
        </small>
      </Styled.Container>

      {error && <strong>{error.message}</strong>}
    </>
  );
};

export const CustomTextArea = forwardRef(CustomTextAreaComponent);
