import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Nunito';

  ${props => props.size && `width: ${props.size};`}

  label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }

  input {
    border-radius: 12px;
  }
`;