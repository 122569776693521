import React, { useCallback } from "react";
import { Modal as RBModal, ModalBody } from "react-bootstrap";
import { MdClose } from "react-icons/md";

import * as S from "./styles";
import { useFineRefundStore } from "../../../../store/fineRefundStore";

export const Modal = ({ type, show = false, onClose, refund }) => {
  const { handleFineRefundAnalysis } = useFineRefundStore();

  const handler = useCallback(() => {
    handleFineRefundAnalysis(refund, Number(type === "approve"));
    onClose();
  }, [handleFineRefundAnalysis, onClose, refund, type]);

  return (
    <RBModal
      style={{ marginTop: "20vh" }}
      size={"lg"}
      show={show}
      onHide={onClose}
    >
      <ModalBody>
        <S.Header>
          <S.Title>Confirme sua ação</S.Title>
          <S.CloseIcon onClick={onClose}>
            <MdClose size={22} />
          </S.CloseIcon>
        </S.Header>
        <S.Body>
          Você deseja realmente {type === "approve" ? "aceitar" : "reprovar"} a
          solicitação?
        </S.Body>
        <S.Footer>
          <button onClick={onClose}>Cancelar</button>
          {type === "approve" && (
            <button className="success" onClick={handler}>
              Aprovar Solicitação
            </button>
          )}
          {type === "reject" && (
            <button className="danger" onClick={handler}>
              Reprovar Solicitação
            </button>
          )}
        </S.Footer>
      </ModalBody>
    </RBModal>
  );
};

export default Modal;
