import styled from "styled-components";
import { Label as InputLabel } from "components/Input/styles";

export const Container = styled.div`
  max-width: 720px;

  position: relative;

  textarea {
    width: 100%;
    height: 120px;

    border: 1px solid ${({ error }) => (error ? "#ff3333" : "#E5EAEE")};
    border-radius: 12px;
    padding: 10px;

    position: relative;

    &:focus {
      outline: 0;
      border-color: ${({ error }) => (error ? "#ff3333" : "#aaa")};
    }
  }

  small {
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #333333;
    font-size: 12px;
    font-weight: 600;
  }

  strong {
    font-size: 14;
    font-weight: 600;
    color: #ff3333;
  }
`;

export const Label = styled(InputLabel)``;
