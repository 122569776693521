import styled from "styled-components";
import { Label as InputLabel } from "components/Input/styles";

const sizes = {
  sm: 200,
  md: 320,
  lg: 460,
};

export const Container = styled.div`
  max-width: ${({ size }) => sizes[size]}px;

  strong {
    display: block;
    font-size: 14;
    font-weight: 600;
    color: #ff3333;

    margin-top: 8px;
  }
`;

export const Label = styled(InputLabel)``;
