import styled from "styled-components";

export const FilterSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  margin-bottom: 3rem;
`;

export const FilterInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-end;

  select {
    height: 44px;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    color: #464e5f;
    background-color: #ffffff;
    border: 1px solid #e5eaee;
    border-radius: 12px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-size: 1rem;
    color: #ffffff;
    font-weight: 600;
    background-color: #ff3333;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }

  i {
    color: #ffffff;
  }
`;

export const FilterInput = styled.input`
  height: 44px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  color: #464e5f;
  background-color: #ffffff;
  border: 1px solid #e5eaee;
  border-radius: 12px;
  width: ${({ width }) => `${width}rem`};
`;
