import React, { useEffect } from "react";

import LoadingOverlay from "react-loading-overlay";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useFinancialAdjustments } from "./useFinancialAdjustments";

import { Pagination, PageLayout } from "../../../components";

import { FilterForm } from "./components/FilterForm";
import { AdjustmentsTable } from "./components/AdjustmentsTable";

import * as Styled from "./styles";

const FinancialAdjustments = () => {
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: {
      idSearchTerm: "",
      merchant: {
        label: "",
        value: "",
      },
      periodSearchTerm: "",
    },
  });

  const [idSearchTerm, merchant, periodSearchTerm] = watch([
    "idSearchTerm",
    "merchant",
    "periodSearchTerm",
  ]);

  const {
    currentPage,
    setCurrentPage,
    periods = [],
    financialAdjustments,
    isLoading,
    isError,
    isEmptyList,
    invalidateQueryKey,
    handleKeyDownToSubmitFilter,
  } = useFinancialAdjustments(idSearchTerm, merchant?.value, periodSearchTerm);

  const history = useHistory();

  useEffect(() => {
    invalidateQueryKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <PageLayout
      headerTitle="Ajustes de fatura"
      headerSubtitle="Crédito ou débito nas faturas do lojista para ajustes"
      buttonIconName="fas fa-plus"
      submitButtonOnBottom={false}
      buttonTitle="Criar ajuste"
      onSubmit={() => {
        history.push("/fiscal/criar-ajuste");
      }}
    >
      <FilterForm
        idSearchTerm={idSearchTerm}
        periodSearchTerm={periodSearchTerm}
        merchantId={merchant?.value}
        register={register}
        control={control}
        periods={periods}
        handleSubmit={handleSubmit}
        handleKeyDownToSubmitFilter={handleKeyDownToSubmitFilter}
        invalidateQueryKey={invalidateQueryKey}
      />

      <LoadingOverlay
        className="w-100"
        active={isLoading}
        spinner
        text="Carregando..."
      >
        <AdjustmentsTable
          isError={isError}
          isEmpty={isEmptyList}
          financialAdjustments={financialAdjustments?.data}
        />
      </LoadingOverlay>

      {financialAdjustments?.paginate_metadata &&
        financialAdjustments?.data?.length > 0 && (
          <Styled.Footer>
            <div>
              <p>
                {financialAdjustments.paginate_metadata.total}{" "}
                {financialAdjustments.paginate_metadata.total === 1
                  ? "resultado"
                  : "resultados"}
              </p>
            </div>

            <Styled.PaginationWrapper>
              <Pagination
                totalPages={financialAdjustments.paginate_metadata.last_page}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Styled.PaginationWrapper>
          </Styled.Footer>
        )}
    </PageLayout>
  );
};

export default withRouter(FinancialAdjustments);
