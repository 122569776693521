import React from "react";
import { Menu } from "./components/Menu";
import { Logo } from "./components/Logo";
import { UserDetail } from "./components/UserDetail";

function Header({ onClick, storeDetails }) {
  return (
    <>
      <div id="kt_header" className="header header-fixed">
        <div
          className="px-4 mw-1370 w-100 mt-0 mx-auto d-flex align-items-stretch justify-content-between p-0"
          style={{ maxWidth: "1370px" }}
        >
          <div className="d-flex align-items-center">
            <Menu onClickMenu={onClick} />
            <Logo storeDetails={storeDetails} />
          </div>
          <UserDetail />
        </div>
      </div>
    </>
  );
}

export default Header;
