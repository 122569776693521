import { useCallback, useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

import api from "../../../services/api";
import { merchantService } from "services";
import { stringUtil } from "utils";

import { PageLayout } from "../../../components";
import {
  TabNavigator,
  DataTab,
  GeneralTab,
  ImageTab,
  ContactsTab,
  AreasTab,
  SchedulesTab,
  AccessTab,
  OnlinePaymentTab,
  ExternalDeliveryTab,
  RegisterReceiptBankAccount,
  ContractTab,
} from "./components";
import {
  calculationWayOptions,
  dispInitialState,
  maritalStatusOptions,
  openingWayOptions,
  paymentStatusOptions,
  statusOptions,
} from "./initialStates";
import { useMerchantManager } from "./hooks";
import { helpers } from "./helpers";
import { useGetMerchantById } from "useCases/merchantUseCases/useGetMerchantById";

export const Merchant = () => {
  const [pagOnCadastro, setPagOnCadastro] = useState();
  const [pagOnPlanosTaxas, setPagOnPlanosTaxas] = useState([]);
  const [listaBancoRecebimentos, setListaBancoRecebimentos] = useState([]);
  const [leveaiVersion, setLeveaiVersion] = useState({
    value: "V2",
    label: "v2",
  });
  const [leveaiConfigurations, setLeveaiConfigurations] = useState({
    externalDelivery: false,
    bindDeliveryMan: false,
  });
  const [statusIntegracaoLeveAi, setStatusIntegracaoLeveAi] = useState(false);
  const [loadingIntegracaoLeveAi, setLoadingIntegracaoLeveAi] = useState(false);
  const [envioImagem, setEnvioImagem] = useState(false);
  const [trava, setTrava] = useState(false);
  const [logs, setLogs] = useState([]);
  const [faturas, setFaturas] = useState([]);
  const [caProcessa, setCaProcessa] = useState(false);
  const [dispDo, setDispDo] = useState(dispInitialState);
  const [dispSg, setDispSg] = useState(dispInitialState);
  const [dispTe, setDispTe] = useState(dispInitialState);
  const [dispQa, setDispQa] = useState(dispInitialState);
  const [dispQi, setDispQi] = useState(dispInitialState);
  const [dispSx, setDispSx] = useState(dispInitialState);
  const [dispSb, setDispSb] = useState(dispInitialState);
  const [formUsuario, setFormUsuario] = useState({
    email: "",
    id_usuario: "",
  });
  const [envioImagemPagOn, setEnvioImagemPagOn] = useState(false);
  const [formCadBanco, setFormCadBanco] = useState({});
  const [modalBanco, setModalBanco] = useState(false);
  const [pagOnBancos, setPagOnBancos] = useState([]);
  const [listaBairrosAtendidos, setListaBairrosAtendidos] = useState([]);
  const [autoAbrir, setAutoAbrir] = useState({ checked: false });
  const [autoFechar, setAutoFechar] = useState({ checked: false });
  const [listaContatos, setListaContatos] = useState([]);
  const [canCallExternalDeliveryWithCode, setCanCallExternalDeliveryWithCode] =
    useState(false);

  const [pageStatus, setPageStatus] = useState("idle");

  const { addToast } = useToasts();

  const { merchantId } = useParams();
  const history = useHistory();

  const { handleSubmit, reset, activeTab, setActiveTab, getValues } =
    useMerchantManager();

  const payments = getValues("payments");

  const {
    merchantData,
    isLoading: isLoadingMerchantData,
    isError: isErrorMerchantData,
    refetch: refetchMerchantData,
  } = useGetMerchantById(merchantId);

  const hasExternalDelivery = getValues("has_external_delivery");

  const populateFields = useCallback(() => {
    try {
      const [merchant] = merchantData.attributes.merchant;

      getPagOnCadastro(merchant.id_estabelecimento);
      postLogsEstabelecimento(merchant.id_estabelecimento);
      postFatura(merchant.id_estabelecimento);
      listarBairrosAtendidos(merchant.id_estabelecimento);
      listarHorarios(merchant.id_estabelecimento);
      listarContatos(merchant.id_estabelecimento);
      getListarUsuario(merchant.id_estabelecimento);
      reset({
        ...merchant,
        data_inicio: merchant.data_inicio
          ? new Date(merchant.data_inicio * 1000)
          : null,
        data_expiracao: merchant.data_expiracao
          ? new Date(merchant.data_expiracao * 1000)
          : null,
        id_cidade: {
          value: merchant.cidade.id_cidade,
          label: `${merchant.cidade.nome_cidade} - ${merchant.cidade.estado}`,
        },
        groups: merchant.categorias.map((group) => ({
          value: String(group.id_grupo),
          label: group.nome,
        })),
        storeTypes: merchant.tipos_loja.map((storeType) => ({
          value: String(storeType.id_tipoloja),
          label: storeType.nome,
        })),
        id_representante: {
          value: merchant.representative?.id_representante,
          label: merchant.representative?.nome,
        },
        status: statusOptions.find(
          (status) => status.value === merchant.status
        ),
        online_tipo: openingWayOptions.find(
          (type) => type.value === merchant.online_tipo
        ),
        forma_calculo: calculationWayOptions.find(
          (type) => type.value === merchant.forma_calculo
        ),
        pagamento_status: paymentStatusOptions.find(
          (payment) => payment.value === merchant.pagamento_status
        ),
        estado_civil: maritalStatusOptions.find(
          (maritalStatus) => maritalStatus.value === merchant.estado_civil
        ),
        mensalidade: stringUtil.maskToRealCurrency(merchant.mensalidade),
        mensalidade_maxima: stringUtil.maskToRealCurrency(
          merchant.mensalidade_maxima
        ),
        comissao: merchant.comissao * 100,
        payments,
      });

      setCanCallExternalDeliveryWithCode(
        merchant.leveai_can_call_external_delivery_with_code
      );

      setLeveaiVersion({
        value: merchant.leveai_version,
        label: merchant.leveai_version?.toLowerCase(),
      });
      setLeveaiConfigurations({
        externalDelivery: merchant.has_external_delivery,
        bindDeliveryMan: merchant.has_task_assignment,
      });
      setStatusIntegracaoLeveAi(merchant.has_external_enabled);

      setEnvioImagem(true);
      setTrava(true);
    } catch (error) {
      addToast("Ocorreu um erro ao buscar, contate o suporte.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }
  }, [addToast, merchantData, reset]);

  function changeIntegracaoLeveai(newValue) {
    setLoadingIntegracaoLeveAi(true);

    if (hasExternalDelivery === false) {
      api
        .post("estabelecimentos/setupExternalDelivery/" + merchantId)
        .then((rps) => {
          var data = rps.data;
          if (data.success === false) {
            Swal.fire({
              title: "Erro!",
              icon: "error",
              html: data.message,
              showCloseButton: true,
              showCancelButton: false,
            });
          } else {
            addToast(data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
            setStatusIntegracaoLeveAi(true);
          }
        })
        .finally(() => setLoadingIntegracaoLeveAi(false));
    }

    if (hasExternalDelivery === true && newValue === true) {
      api
        .get("estabelecimentos/enableExternalDelivery/" + merchantId)
        .then((rps) => {
          var data = rps.data;
          if (data.success === false) {
            Swal.fire({
              title: "Erro!",
              icon: "error",
              html: data.message,
              showCloseButton: true,
              showCancelButton: false,
            });
          } else {
            addToast(data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
            setStatusIntegracaoLeveAi(true);
          }
        })
        .finally(() => setLoadingIntegracaoLeveAi(false));
    } else if (hasExternalDelivery === true && newValue === false) {
      api
        .get("estabelecimentos/disableExternalDelivery/" + merchantId)
        .then((rps) => {
          var data = rps.data;
          if (data.success === false) {
            Swal.fire({
              title: "Erro!",
              icon: "error",
              html: data.message,
              showCloseButton: true,
              showCancelButton: false,
            });
          } else {
            addToast(data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
            setStatusIntegracaoLeveAi(false);
          }
        })
        .finally(() => setLoadingIntegracaoLeveAi(false));
    }

    setLoadingIntegracaoLeveAi(false);
  }

  function handleLeveaiIntegrationVersion(event) {
    setLeveaiVersion(event);
    setLeveaiConfigurations({
      externalDelivery: false,
      bindDeliveryMan: false,
    });
  }

  function getListarUsuario(idEstabelecimento) {
    var data = {
      id_estabelecimento: idEstabelecimento,
    };

    api.post("usuarios/listarIdEstabelecimento", data).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        setFormUsuario(rps.data.obj);
      } else {
        setFormUsuario([]);
      }
    });
  }

  function listarContatos(idEstabelecimento) {
    var data = {
      idEstabelecimento: idEstabelecimento,
    };

    api.post("estabelecimentoscontatos/listarContatos", data).then((rps) => {
      setListaContatos(rps.data.obj);
    });
  }

  function listarHorarios(id_estabelecimento) {
    api
      .post("estabelecimentoshorarios/listarHorarios", {
        id_estabelecimento: id_estabelecimento,
      })
      .then((rps) => {
        setAutoAbrir({ checked: rps.data.auto_abrir });
        setAutoFechar({ checked: rps.data.auto_fechar });

        rps.data.obj.forEach((ob) => {
          if (ob.abreviacao === "dom") {
            setDispDo({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
          if (ob.abreviacao === "seg") {
            setDispSg({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
          if (ob.abreviacao === "ter") {
            setDispTe({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
          if (ob.abreviacao === "qua") {
            setDispQa({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
          if (ob.abreviacao === "qui") {
            setDispQi({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
          if (ob.abreviacao === "sex") {
            setDispSx({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
          if (ob.abreviacao === "sab") {
            setDispSb({
              checked: ob.checked,
              initAt: ob.abertura,
              endAt: ob.fechamento,
              initAt2: ob.abertura2,
              endAt2: ob.fechamento2,
            });
          }
        });
      });
  }

  function listarBairrosAtendidos(idEstabelecimento) {
    var data = {
      idEstabelecimento: idEstabelecimento,
    };

    api.post("estabelecimentos/listarBairrosAtendidos", data).then((rps) => {
      setListaBairrosAtendidos(rps.data.obj);
    });
  }

  function postFatura(idEstabelecimento) {
    var data = {
      idEstabelecimento: idEstabelecimento,
    };

    api.post("estabelecimentos/faturas", data).then((rps) => {
      setFaturas(rps.data.obj);
    });
  }

  function postLogsEstabelecimento(idEstabelecimento) {
    var data = {
      idEstabelecimento: idEstabelecimento,
    };

    api.post("estabelecimentos/exibirlogs", data).then((rps) => {
      setLogs(rps.data.obj);
    });
  }

  function getListarPlanosTaxas() {
    api.get("pagamentoonline/listarPlanosTaxas").then((rps) => {
      setPagOnPlanosTaxas(rps.data.obj);
    });
  }

  function getListarBancoRecebimentos(idPagOnCadastro) {
    var data = {
      idPagOnCadastro: idPagOnCadastro,
    };

    api.post("pagamentoonline/listarBancoRecebimentos", data).then((rps) => {
      setListaBancoRecebimentos(rps.data.obj);
    });
  }

  function getPagOnCadastro(idEstabelecimento) {
    var data = {
      id_estabelecimento: idEstabelecimento,
    };

    api.post("pagamentoonline/listarPagOnCadastro", data).then((rps) => {
      setPagOnCadastro(rps.data.obj);
    });
  }

  function getListarBanco() {
    api.get("pagamentoonline/listarBancos").then((rps) => {
      var bancos = rps.data.obj;
      var nBancos = [];
      bancos.forEach((e) => {
        nBancos[nBancos.length] = {
          value: e.cod,
          label: e.cod + " - " + e.banco,
        };
      });
      setPagOnBancos(nBancos);
    });
  }

  function salvarBancoPagOn() {
    setCaProcessa(true);
    api
      .post("pagamentoonline/adicionarPagOnBanco", formCadBanco)
      .then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });

          getListarBancoRecebimentos(merchantId);
          setModalBanco(false);
          setCaProcessa(false);
        } else {
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.mensagem,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
  }

  function fecharModalBanco() {
    setModalBanco(false);
  }

  function editarPagOnBanco(idPagOnBanco) {
    var banco = JSON.parse(JSON.stringify(idPagOnBanco));

    getListarBanco();
    setFormCadBanco(banco);
    setModalBanco(true);
  }

  function excluirPagOnBanco(idPagOnBanco) {
    var banco = JSON.parse(JSON.stringify(idPagOnBanco));

    setCaProcessa(true);

    api.post("pagamentoonline/excluirPagOnBanco", banco).then((rps) => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });

        getListarBancoRecebimentos(merchantId);
        setCaProcessa(false);
      }
    });
  }

  const onSubmit = async (data) => {
    try {
      setPageStatus("loading-submit");

      const isEditMerchant = merchantId;

      const formattedMerchantConfig = {
        status: data.status.value,
        groups: data.groups.map((group) => group.value),
        store_types: data.storeTypes.map((storeType) => storeType.value),
        payments: data.payments.map((payment) => payment.id),
      };

      let formattedContractFields = {};

      const availableFieldsToEdit = {
        razao_social: data.razao_social,
        nome: data.nome,
        rg: data.rg,
        orgao_expedidor: data.orgao_expedidor,
        lat: data.lat,
        lng: data.lng,
        entrega_minimo: data.entrega_minimo,
        entrega_maximo: data.entrega_maximo,
        id_estabelecimento_pai: data.id_estabelecimento_pai,
        aceita_cupom: data.aceita_cupom,
        cobrar_multa: data.cobrar_multa,
        upload_em_massa: data.upload_em_massa,
        auto_aceitar_pedido: data.auto_aceitar_pedido,
        entrega_desativada: data.entrega_desativada,
        retirada_desativada: data.retirada_desativada,
        frase_promocional: data.frase_promocional,
        soon: data.soon,
        exclusivo_aiboo: data.exclusivo_aiboo,
        aceita_pgto_presencial: data.aceita_pgto_presencial,
        mensalidade: stringUtil.removeRealCurrencyMask(data.mensalidade),
        mensalidade_maxima: stringUtil.removeRealCurrencyMask(
          data.mensalidade_maxima
        ),
        pagamento_status: data.pagamento_status?.value ?? "P",
        forma_calculo: data.forma_calculo.value,
        online_tipo: data.online_tipo.value,
        estado_civil: data.estado_civil?.value,
        id_representante: data.id_representante
          ? Number(data.id_representante.value)
          : null,
        is_swimlane_view: data.is_swimlane_view,
        leveai_version: leveaiVersion.value,
        has_external_delivery: leveaiConfigurations.externalDelivery,
        has_task_assignment: leveaiConfigurations.bindDeliveryMan,
      };
     

      let formattedMerchantData = availableFieldsToEdit;

      const hasContract = merchantData?.attributes.merchant[0].contract_uuid;

      if (!isEditMerchant || (isEditMerchant && !hasContract)) {
        formattedContractFields = {
          cnpj_cpf: data.cnpj_cpf,
          endereco_rua: data.endereco_rua,
          endereco_numero: data.endereco_numero,
          endereco_bairro: data.endereco_bairro,
          id_cidade: Number(data.id_cidade.value),
          endereco_cep: data.endereco_cep,
          email: data.email,
          contato: data.contato,
          cpf_contato: data.cpf_contato,
          comissao: data.comissao,
        };
      }

      if (!formattedMerchantData.id_representante) {
        delete formattedMerchantData.id_representante;
      }

      if (isEditMerchant) {
        await Promise.all([
          merchantService.updateMerchantConfig({
            merchantId,
            config: formattedMerchantConfig,
          }),
          merchantService.updateMerchant({
            merchantId,
            data: {
              ...formattedContractFields,
              ...formattedMerchantData,
            },
          }),
        ]);

        addToast("Estabelecimento atualizado com sucesso.", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });

        setEnvioImagem(true);
        setTrava(true);

        return;
      }

      const response = await merchantService.createMerchant({
        ...formattedContractFields,
        ...formattedMerchantData,
      });

      await merchantService.updateMerchantConfig({
        merchantId: response.merchant.id_estabelecimento,
        config: formattedMerchantConfig,
      });

      addToast("Estabelecimento criado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });

      setEnvioImagem(true);
      setTrava(true);

      history.replace(
        `estabelecimento/${response.merchant.id_estabelecimento}`,
        { shallow: true }
      );
    } catch (error) {
     
      const errorMessage = helpers.getErrorMessage(error);

      addToast(
        `Ocorreu um erro ao salvar a empresa, tente novamente. ${
          errorMessage ? `"${errorMessage}"` : ""
        }`,
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        }
      );
    } finally {
      setPageStatus("idle");
    }
  };

  const handleChangeCallExternalDeliveryWithCode = useCallback(
    (value) => {
      setCanCallExternalDeliveryWithCode(value);

      merchantService.updateMerchant({
        merchantId,
        data: {
          leveai_can_call_external_delivery_with_code: value,
        },
      });
    },
    [merchantId]
  );

  useEffect(() => {
    if (merchantData) {
      populateFields();
    }

    if (isErrorMerchantData) {
      addToast("Estabelecimento não encontrado.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    }

    return () => {
      reset({
        payments: [],
        groups: [],
        storeTypes: [],
      });
    };
  }, [addToast, isErrorMerchantData, merchantData, populateFields, reset]);

  useEffect(() => {
    setActiveTab("data-tab");
  }, [setActiveTab]);

  return (
    <PageLayout
      headerTitle="Configuração de empresa"
      headerSubtitle="Crie um novo estabelecimento ou edite suas definições"
      buttonTitle="Salvar"
      buttonsProps={{
        disabled: pageStatus === "loading-submit",
        isLoading: pageStatus === "loading-submit",
      }}
      submitButtonOnTop={false}
      submitButtonOnBottom={activeTab === "data-tab"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TabNavigator
        merchantId={merchantId}
        trava={trava}
        setPagOnCadastro={setPagOnCadastro}
        getListarPlanosTaxas={getListarPlanosTaxas}
        getListarBancoRecebimentos={getListarBancoRecebimentos}
      />

      <LoadingOverlay
        active={isLoadingMerchantData}
        spinner
        text="Carregando..."
      >
        <div className="tab-content mb-8">
          <GeneralTab
            merchantId={merchantId}
            setTrava={setTrava}
            logs={logs}
            dataFatura={faturas}
          />

          <DataTab merchantId={merchantId} />

          <ImageTab merchantId={merchantId} envioImagem={envioImagem} />

          <ContactsTab
            merchantId={merchantId}
            setCaProcessa={setCaProcessa}
            caProcessa={caProcessa}
            listaContatos={listaContatos}
            listarContatos={listarContatos}
          />

          <AreasTab
            merchantId={merchantId}
            caProcessa={caProcessa}
            setCaProcessa={setCaProcessa}
            listaBairrosAtendidos={listaBairrosAtendidos}
            listarBairrosAtendidos={listarBairrosAtendidos}
          />

          <SchedulesTab
            merchantId={merchantId}
            dispDo={dispDo}
            setDispDo={setDispDo}
            dispSg={dispSg}
            setDispSg={setDispSg}
            dispTe={dispTe}
            setDispTe={setDispTe}
            dispQa={dispQa}
            setDispQa={setDispQa}
            dispQi={dispQi}
            setDispQi={setDispQi}
            dispSx={dispSx}
            setDispSx={setDispSx}
            dispSb={dispSb}
            setDispSb={setDispSb}
            autoAbrir={autoAbrir}
            setAutoAbrir={setAutoAbrir}
            autoFechar={autoFechar}
            setAutoFechar={setAutoFechar}
          />

          <AccessTab
            merchantId={merchantId}
            formUsuario={formUsuario}
            setFormUsuario={setFormUsuario}
          />

          <OnlinePaymentTab.Register
            pagOnCadastro={pagOnCadastro}
            setPagOnCadastro={setPagOnCadastro}
            setEnvioImagemPagOn={setEnvioImagemPagOn}
          />

          <OnlinePaymentTab.Files
            merchantId={merchantId}
            envioImagemPagOn={envioImagemPagOn}
            est_imgIdentificacao={pagOnCadastro?.est_imgIdentificacao}
            est_imgResidencia={pagOnCadastro?.est_imgResidencia}
            est_imgAtividade={pagOnCadastro?.est_imgAtividade}
            id_pagon_cadastro={pagOnCadastro?.id_pagon_cadastro}
            setPagOnCadastro={setPagOnCadastro}
          />

          <OnlinePaymentTab.Plans
            merchantId={merchantId}
            caProcessa={caProcessa}
            setCaProcessa={setCaProcessa}
            id_pagon_planos_taxas={pagOnCadastro?.id_pagon_planos_taxas}
            id_pagon_cadastro={pagOnCadastro?.id_pagon_cadastro}
            pagOnPlanosTaxas={pagOnPlanosTaxas}
            getPagOnCadastro={getPagOnCadastro}
          />

          <OnlinePaymentTab.Receipts
            merchantId={merchantId}
            caProcessa={caProcessa}
            setCaProcessa={setCaProcessa}
            getListarBancoRecebimentos={getListarBancoRecebimentos}
            listaBancoRecebimentos={listaBancoRecebimentos}
            setModalBanco={setModalBanco}
            setFormCadBanco={setFormCadBanco}
            getListarBanco={getListarBanco}
            id_pagon_cadastro={pagOnCadastro?.id_pagon_cadastro}
            editarPagOnBanco={editarPagOnBanco}
            excluirPagOnBanco={excluirPagOnBanco}
          />

          <ExternalDeliveryTab
            leveaiVersion={leveaiVersion}
            leveaiConfigurations={leveaiConfigurations}
            loadingIntegracaoLeveAi={loadingIntegracaoLeveAi}
            statusIntegracaoLeveAi={statusIntegracaoLeveAi}
            setLeveaiConfigurations={setLeveaiConfigurations}
            changeIntegracaoLeveai={changeIntegracaoLeveai}
            handleLeveaiIntegrationVersion={handleLeveaiIntegrationVersion}
            canCallExternalDeliveryWithCode={canCallExternalDeliveryWithCode}
            handleChangeCallExternalDeliveryWithCode={
              handleChangeCallExternalDeliveryWithCode
            }
          />
          {/* 
          <ContractTab
            merchantId={merchantId}
            refetchMerchantData={refetchMerchantData}
          /> */}
        </div>
      </LoadingOverlay>

      <RegisterReceiptBankAccount
        modalBanco={modalBanco}
        fecharModalBanco={fecharModalBanco}
        pagOnBancos={pagOnBancos}
        formCadBanco={formCadBanco}
        setFormCadBanco={setFormCadBanco}
        salvarBancoPagOn={salvarBancoPagOn}
      />
    </PageLayout>
  );
};
