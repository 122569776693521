import apiV2 from "services/api-laravelv2";

const createFinancialAdjustment = async ({
  merchant_id,
  financial_period_id,
  value,
  description,
  type,
}) => {
  const response = await apiV2.post("custom-transactions", {
    merchant_id,
    financial_period_id,
    value,
    description,
    type,
  });

  return response.data;
};

const listFinancialAdjustments = async ({
  id,
  financial_period_id,
  merchant_id,
  per_page,
  page,
}) => {
  const response = await apiV2.get("custom-transactions", {
    params: {
      id,
      financial_period_id,
      merchant_id,
      per_page,
      page,
    },
  });

  return response.data;
};

const listFinancialPeriods = async ({ only_open }) => {
  const response = await apiV2.get("financial-periods", {
    params: { only_open, per_page: 5000 },
  });

  return response.data;
};

export const financeService = {
  createFinancialAdjustment,
  listFinancialAdjustments,
  listFinancialPeriods,
};
