import { useQuery } from "@tanstack/react-query";
import { financeService } from "services";
import { financeQueryKeys } from "./financeQueryKeys";

export const useListFinancialPeriods = ({ only_open = true }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [financeQueryKeys.listFinancialPeriods],
    queryFn: () => financeService.listFinancialPeriods({ only_open }),
    retry: false,
  });

  return {
    periods: data,
    isLoading,
    isError,
  };
};
