import { financeService } from "services";
import { useMutation } from "@tanstack/react-query";

export const useCreateFinancialAdjustment = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: ({
      merchant_id,
      financial_period_id,
      value,
      description,
      type,
    }) =>
      financeService.createFinancialAdjustment({
        merchant_id,
        financial_period_id,
        value,
        description,
        type,
      }),
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });

  const createFinancialAdjustment = ({
    merchant_id,
    financial_period_id,
    value,
    description,
    type,
  }) => {
    mutation.mutate({
      merchant_id,
      financial_period_id,
      value,
      description,
      type,
    });
  };

  return {
    createFinancialAdjustment,
    isLoading: mutation.isPending,
    isError: mutation.isError,
  };
};
