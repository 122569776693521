import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import { debounce } from "lodash";

import { getLongMonthAndYear } from "utils";

import { CustomSelect } from "pages/fiscal/CreateFinancialAdjustment/components";
import { formatMerchantLabel } from "pages/fiscal/CreateFinancialAdjustment/helpers";

import { merchantService } from "services";

import * as Styled from "./styles";

export const FilterForm = ({
  merchantId,
  idSearchTerm,
  periodSearchTerm,
  register,
  control,
  periods,
  handleSubmit,
  handleKeyDownToSubmitFilter,
  invalidateQueryKey,
}) => {
  const getAsyncOptions = async (inputText) => {
    const candidate = inputText.toLowerCase();
    const byLabel = ({ label }) => label.toLowerCase().includes(candidate);

    const response = await merchantService.listByIdOrName({ param: inputText });

    const formattedResponse = response?.estabelecimentos?.map((merchant) => ({
      label: formatMerchantLabel(merchant),
      value: merchant.id_estabelecimento,
    }));

    return formattedResponse?.filter(byLabel);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options) => callback(options));
    }, 1000),
    []
  );

  return (
    <Styled.FilterSectionWrapper>
      <Styled.FilterInputWrapper>
        <label>Código</label>

        <Styled.FilterInput
          type="text"
          value={idSearchTerm}
          onKeyDown={handleKeyDownToSubmitFilter}
          width={7}
          {...register("idSearchTerm")}
        />
      </Styled.FilterInputWrapper>

      <Styled.FilterInputWrapper style={{ width: 460 }}>
        <label>Loja</label>

        <Controller
          name="merchant"
          control={control}
          rules={{ required: "O campo estabelecimento é obrigatório." }}
          render={({ field }) => (
            <CustomSelect
              {...field}
              async
              loadOptions={loadOptions}
              noOptionsMessage={() => "Estabelecimento não encontrado"}
              loadingMessage={() => "Carregando..."}
              placeholder=""
              isClearable={!!merchantId}
              size="lg"
            />
          )}
        />
      </Styled.FilterInputWrapper>

      <Styled.FilterInputWrapper>
        <label>Período financeiro</label>

        <select
          value={periodSearchTerm}
          onKeyDown={handleKeyDownToSubmitFilter}
          {...register("periodSearchTerm")}
        >
          <option value="" selected></option>

          {periods?.map((period) => (
            <option value={period.id}>
              {getLongMonthAndYear(new Date(period.starts_at))}
            </option>
          ))}
        </select>
      </Styled.FilterInputWrapper>

      <Styled.FilterInputWrapper>
        <button
          onClick={() => handleSubmit(invalidateQueryKey)()}
          style={{
            width: 150,
          }}
        >
          <i className="la la-filter" />
          Filtrar
        </button>
      </Styled.FilterInputWrapper>
    </Styled.FilterSectionWrapper>
  );
};
