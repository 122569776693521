import React from "react";

import PageHeader from "../PageHeader";
import Button from "../Button";

import * as Styled from "./styles";

export const PageLayout = ({
  headerTitle,
  headerSubtitle,
  buttonTitle = "Salvar",
  onSubmit,
  children,
  buttonIconName,
  submitButtonOnTop = true,
  submitButtonOnBottom = true,
  buttonsProps,
}) => {
  return (
    <Styled.Container>
      <PageHeader title={headerTitle} subTitle={headerSubtitle}>
        {submitButtonOnTop && (
          <Button
            onClick={onSubmit}
            type="submit"
            style={{
              width: 150,
              borderColor: buttonsProps?.borderColor,
              backgroundColor: buttonsProps?.backgroundColor,
            }}
            {...buttonsProps}
          >
            <Styled.Icon className={buttonIconName} />

            {buttonsProps?.isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span className="d-block">{buttonTitle}</span>
            )}
          </Button>
        )}
      </PageHeader>

      <Styled.Content>
        {children}

        {submitButtonOnBottom && (
          <Styled.Footer>
            <Button
              size="medium"
              onClick={onSubmit}
              type="submit"
              style={{
                width: 150,
                borderColor: buttonsProps?.borderColor,
                backgroundColor: buttonsProps?.backgroundColor,
              }}
              {...buttonsProps}
            >
              <Styled.Icon className={buttonIconName} />

              {buttonsProps?.isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>{buttonTitle}</span>
              )}
            </Button>
          </Styled.Footer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};
