import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Nunito';
  width: 100%;
  max-width: 1560px;
  padding: 42px 36px;
  min-height: 100vh;
  margin: 0 auto;

  p {
    font-size: 14px;
  }

  & .p12 {
    margin-left: 12px;
  }
`;

export const Content = styled.div`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  margin-top: 40px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: flex-end;
`;
