import React, { useState } from "react";

import * as Styled from "./styles";

export const ExpandableText = ({ text, truncateLength = 60 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {!isExpanded ? (
        <Styled.TruncatedText onClick={toggleExpanded}>
          {text.length > truncateLength ? (
            <div>
              {text.slice(0, truncateLength)}...{" "}
              <span style={{ color: "red" }}>ver mais</span>
            </div>
          ) : (
            text
          )}
        </Styled.TruncatedText>
      ) : (
        <Styled.ExpandedText onClick={toggleExpanded}>
          {text}
        </Styled.ExpandedText>
      )}
    </>
  );
};
