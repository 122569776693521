import React from "react";
import * as Styled from "./styles";

export const ErrorFeedBack = ({ troubleType }) => {
  const layoutMap = {
    emptyList: {
      title: "Nenhum ajuste financeiro encontrado",
      description: "Tente pesquisar por outro termo  ou crie um novo ajuste",
      imageSrc: "assets/imgs/no-results.svg",
    },
    errorList: {
      title: "Houve um erro ao carregar essa página",
      description: "Tente recarregá-la ou volte mais tarde",
      imageSrc: "assets/imgs/page-error.svg",
    },
  };

  return (
    <Styled.TroubleInListWrapper>
      <Styled.TroubleInListContentWrapper>
        <div>
          <img
            alt={layoutMap[troubleType].title}
            src={layoutMap[troubleType].imageSrc}
          />
        </div>

        <Styled.TroubleInListMessageTextWrapper>
          <h3>{layoutMap[troubleType].title}</h3>

          <p>{layoutMap[troubleType].description}</p>
        </Styled.TroubleInListMessageTextWrapper>
      </Styled.TroubleInListContentWrapper>
    </Styled.TroubleInListWrapper>
  );
};
