import styled from 'styled-components';
import { MdOutlineSearch } from "react-icons/md";

export const Container = styled.button`
    font-family: 'Nunito';
    height: 44px;
    background-color: #FF3333;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 600;
`;

export const Icon = styled(MdOutlineSearch)`
    font-size: 24px;
    color: #fff;
    margin-right: 8px;
`;
