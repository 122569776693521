import Axios from "axios";
import AxiosLogger from "../axiosLogger";


const Apiv2 = Axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL_V2,
  headers: {
       //"content-type": "application/x-www-form-urlencoded", // content-type accept for CodeIgniter
    //'Authorization' : 'Basic ' +  localStorage.getItem("hash") ? localStorage.getItem("hash") : 'null'
    Authorization: localStorage.getItem("@ADM-Aiboo/token_session")
      ? localStorage.getItem("@ADM-Aiboo/token_session")
      : "null",
  }
});

AxiosLogger(Apiv2, localStorage.getItem("@ADM-Aiboo/hash"));


Apiv2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.config.hasOwnProperty("errorHandle") &&
      error.config.errorHandle === false
    ) {
      return Promise.reject(error);
    }
    return error;
  }
);

export default Apiv2;
