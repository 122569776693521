import styled, { css } from "styled-components";
import { Label as InputLabel } from "components/Input/styles";

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

export const Label = styled(InputLabel)``;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  width: 160px;
  height: 44px;
  padding: 12px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  border: 1px solid #ff3333;

  span {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #ff3333;

      span {
        color: white;
      }
    `}
`;
