import React, { forwardRef } from "react";
import SelectAsync from "react-select/async";
import Select from "react-select";

import * as Styled from "./styles";

const CustomSelectComponent = (
  { label, size = "md", error, async = false, isMandatory = true, ...rest },
  ref
) => {
  const Component = async ? SelectAsync : Select;

  const getBorderColor = (isFocused) => {
    if (error) {
      return "#ff3333";
    }

    return isFocused ? "#AAAAAA" : "#E5EAEE";
  };

  return (
    <Styled.Container size={size}>
      {label && (
        <Styled.Label>
          {label} {isMandatory && <span>*</span>}
        </Styled.Label>
      )}

      <Component
        ref={ref}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            maxHeight: 44,
            borderRadius: 12,
            borderColor: getBorderColor(state.isFocused),
            "&:hover": {
              borderColor: getBorderColor(state.isFocused),
            },
            boxShadow: "none",
            padding: 6,
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            fontFamily: "Nunito",
            fontWeight: 600,
            color: "##E5EAEE",
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 5,
            paddingBottom: 5,
          }),
          clearIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 5,
            paddingBottom: 5,
          }),
        }}
        {...rest}
      />

      {error && <strong>{error.message}</strong>}
    </Styled.Container>
  );
};

export const CustomSelect = forwardRef(CustomSelectComponent);
