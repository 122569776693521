import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { useListFinancialPeriods, useListFinancialAdjustments } from "useCases";

import { financeQueryKeys } from "useCases/financeUseCases/financeQueryKeys";

export const useFinancialAdjustments = (
  idSearchTerm,
  merchantId,
  periodSearchTerm
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    financialAdjustments,
    isLoading: isLoadingAdjustments,
    isError,
    error,
  } = useListFinancialAdjustments({
    id: idSearchTerm,
    financial_period_id: periodSearchTerm,
    merchant_id: merchantId,
    page: currentPage,
    per_page: 4,
  });

  const { periods, isLoading: isLoadingPeriods } = useListFinancialPeriods({
    only_open: 0,
  });

  const isEmptyList = error?.request?.status === 404;

  const queryClient = useQueryClient();

  const isLoading = isLoadingAdjustments || isLoadingPeriods;

  const invalidateQueryKey = async () => {
    await queryClient.invalidateQueries({
      queryKey: [financeQueryKeys.listFinancialAdjustments],
    });
  };

  const handleKeyDownToSubmitFilter = (event) => {
    if (event.code === "Enter") {
      invalidateQueryKey();
      setCurrentPage(1);
    }
  };

  return {
    currentPage,
    setCurrentPage,
    periods: periods?.data,
    financialAdjustments,
    isLoading,
    isError: isError && !isEmptyList,
    isEmptyList,
    invalidateQueryKey,
    handleKeyDownToSubmitFilter,
  };
};
