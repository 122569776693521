import { useQuery } from "@tanstack/react-query";
import { financeQueryKeys } from "./financeQueryKeys";
import { financeService } from "services";

export const useListFinancialAdjustments = ({
  id,
  financial_period_id,
  merchant_id,
  per_page,
  page,
}) => {
  const { data, isFetching, isError, error } = useQuery({
    queryKey: [financeQueryKeys.listFinancialAdjustments],
    queryFn: () =>
      financeService.listFinancialAdjustments({
        id,
        financial_period_id,
        merchant_id,
        per_page,
        page,
      }),
    retry: false,
  });

  return {
    financialAdjustments: data,
    isLoading: isFetching,
    isError,
    error,
  };
};
