import React, { useCallback } from "react";
import { MdBlock, MdCheck } from "react-icons/md";
import { IoMdOpen } from "react-icons/io";
import { Modal } from "../Modal";
import { format } from "date-fns";

import { ExpandableText } from "../ExpandableText";
import { useFineRefundStore } from "../../../../store/fineRefundStore";

import * as S from "./styles";

export function Table() {
  const { data } = useFineRefundStore();

  const [modal, setModal] = React.useState(false);
  const [selectedRefund, setSelectedRefund] = React.useState(null);
  const [modalType, setModalType] = React.useState("approve");

  const handleOpenModal = useCallback((type, id) => {
    setModalType(type);
    setSelectedRefund(id);
    setModal(true);
  }, []);

  return (
    <S.Container className="col-md-12">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Pedido</th>
            <th style={{ width: "10%" }}>Data</th>
            <th style={{ width: "15%" }}>Estabelecimento</th>
            <th style={{ width: "15%" }}>Cidade</th>
            <th style={{ width: "40%" }}>Motivo</th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr>
              <td className="cursor-pointer align-middle">
                #{item.order_hash} <IoMdOpen size={20} />
              </td>
              <td>
                {!!item?.created_at &&
                  format(new Date(item.created_at), "dd/MM/yyyy")}
              </td>
              <td>{item?.order?.merchant_name}</td>
              <td>{item?.order?.city}</td>
              <td>
                <ExpandableText text={item.description} />
              </td>
              {item.is_approved === 1 && (
                <td className="center">
                  <MdCheck />
                  {" Aprovado"}
                </td>
              )}
              {item.is_approved === 0 && (
                <td className="center">
                  <MdBlock />
                  {" Reprovado"}
                </td>
              )}
              {item.is_approved === null && (
                <td className="center cursor-pointer">
                  <div className="d-flex" style={{ gap: 10 }}>
                    <div
                      style={{ color: "#00B333" }}
                      onClick={() => handleOpenModal("approve", item.id)}
                    >
                      <MdCheck />
                      {" Aprovar"}
                    </div>
                    <div
                      style={{ color: "#FF3333" }}
                      onClick={() => handleOpenModal("reject", item.id)}
                    >
                      <MdBlock />
                      {" Reprovar"}
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        show={modal}
        type={modalType}
        onClose={() => setModal(false)}
        refund={selectedRefund}
      />
    </S.Container>
  );
}
