import React, { useState } from "react";
import styled from "styled-components";

const TruncatedText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const ExpandedText = styled.div`
  cursor: pointer;
`;

export const ExpandableText = ({ text, truncateLength = 60 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {!isExpanded ? (
        <TruncatedText onClick={toggleExpanded}>
          {text.length > truncateLength ? (
            <div>
              {text.slice(0, truncateLength)}...{" "}
              <span style={{ color: "red" }}>ver mais</span>
            </div>
          ) : (
            text
          )}
        </TruncatedText>
      ) : (
        <ExpandedText onClick={toggleExpanded}>{text}</ExpandedText>
      )}
    </>
  );
};
