import styled from "styled-components";

export const Footer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PaginationWrapper = styled.div`
  grid-column: 2;
  justify-self: center;
  width: fit-content;
`;
