import React, { useEffect } from "react";
import { Table } from "./Table";
import { Pagination } from "../../../components/Pagination";

import * as S from "./styles";
import { Input } from "./Input";
import { InputSelect } from "./InputSelect";
import { SearchButton } from "./SearchButton";

import { useFineRefundStore } from "../../../store/fineRefundStore";

export function FineRefund() {
  const {
    fineRefundRequest,
    setOrderHash,
    total,
    last_page,
    setCurrentPage,
    current_page,
    handleSearch,
  } = useFineRefundStore();

  useEffect(() => {
    fineRefundRequest();
  }, [fineRefundRequest]);

  return (
    <S.Container>
      <h2 className="p12">Solicitações de estorno de multa</h2>
      <p className="p12">
        Pedidos para estorno de multa por pedidos recusados.
      </p>
      <S.Content>
        <S.Controls
          className="d-flex"
          style={{ gap: 35, marginBottom: 44, marginLeft: 4 }}
        >
          <Input label="Pedido" size="100px" onChange={setOrderHash} />
          <InputSelect
            label="Nome do estabelecimento"
            endpoint="/estabelecimentos/listar"
            field="filtro"
            size="420px"
          />
          <SearchButton onClick={handleSearch} />
        </S.Controls>
        <Table />
        <div
          className="w-100 d-flex justify-content-between align-middle"
          style={{ marginTop: 10, marginBottom: 10, marginLeft: 20 }}
        >
          <div style={{ width: 200 }}>
            <p>
              {total} resultados encontrados
              {/* {filteredTransactions.length > 1 && "s"} */}
            </p>
          </div>
          <Pagination
            totalPages={last_page}
            currentPage={current_page}
            setCurrentPage={setCurrentPage}
          />
          <div style={{ width: 200 }}></div>
        </div>
      </S.Content>
    </S.Container>
  );
}
