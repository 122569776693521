import React, { useState, useEffect, useCallback } from "react";

import * as S from "./styles";
import Api from "../../../../services/api";
import { useFineRefundStore } from "../../../../store/fineRefundStore";

export const InputSelect = ({
  endpoint,
  label = "",
  debounceTime = 500,
  size = "100%",
  field = "search",
}) => {
  const { setMerchantId } = useFineRefundStore();
  const [hasClicked, setHasClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [, setSelectedResult] = useState("");

  const fetchResults = useCallback(async () => {
    try {
      const response = await Api.post(`${endpoint}`, {
        [field]: searchTerm,
      });

      setSearchResults(
        response.data.estabelecimentos.map((result) => ({
          id: result.id_estabelecimento,
          name: result.nome,
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [endpoint, field, searchTerm]);

  useEffect(() => {
    if (hasClicked) {
      return;
    }

    const timer = setTimeout(() => {
      if (searchTerm.length >= 2) {
        fetchResults();
      } else {
        setSearchResults([]);
      }
    }, debounceTime);

    return () => clearTimeout(timer);
  }, [debounceTime, fetchResults, hasClicked, searchTerm]);

  const handleInputChange = useCallback(
    (event) => {
      setHasClicked(false);
      setMerchantId(null);
      setSearchTerm(event.target.value);
    },
    [setMerchantId]
  );

  const handleResultClick = useCallback(
    (result) => {
      setSelectedResult(result.id);
      setMerchantId(result.id);
      setSearchTerm(result.name);
      setHasClicked(true);
    },
    [setMerchantId]
  );

  return (
    <S.Container size={size}>
      {!!label && <label>{label}</label>}
      <input
        type="text"
        className="form-control"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Procurar por nome ou id..."
      />
      <S.SearchBox hide={(!searchResults.length && !searchTerm) || hasClicked}>
        {searchResults.map((result) => (
          <div key={result.id} onClick={() => handleResultClick(result)}>
            {result.id} - {result.name}
          </div>
        ))}
        {!searchResults.length && (
          <div>
            {searchTerm && !hasClicked ? "Nenhum resultado encontrado" : ""}
          </div>
        )}
      </S.SearchBox>
    </S.Container>
  );
};
