import React, { useState, useMemo } from "react";

import Api from "../services/api";
import Header from "./Header";
import useRoles from "../utils/useRoles";

import {
  PainelIcon,
  EstabelecimentoIcon,
  PagamentoIcon,
  RepresentantesIcon,
  FiscalIcon,
  MarketingIcon,
  FerramentasIcon,
  UsuariosIcon,
  PedidosIcon,
  TicketsIcon,
  LogoutIcon,
  RelatorioIcon,
  ConfigIcon,
} from "./Icons";

import { Menu, SubMenu, MenuSection } from "./Aside";
import { authService } from "services";

function Layout({ children }) {
  const { doIHaveThisRole } = useRoles();
  const [collapsed, setCollapsed] = useState(true);
  const [menus, setMenus] = useState({});
  // const [detalhesLoja, setDetalhesLoja] = useState({});

  const toggleCollapsed = () => {
    setCollapsed((value) => !value);
  };

  const classe = useMemo(() => {
    return !collapsed ? "aside-on" : "";
  }, [collapsed]);

  const getDetalhesLoja = (ret) => {
    Api.get("estabelecimento/getLoja").then((rps) => {
      if (rps?.data?.status === true) {
        // setDetalhesLoja(rps.data.estabelecimento);
        ret(rps.data.estabelecimento);
      }
    });
  };

  const alternaMenu = (item) => {
    var m = {};
    if (menus[item] === undefined || menus[item] === "") {
      m[item] = "menu-item-open";
    } else {
      m[item] = "";
    }

    setMenus(m);
  };

  const doLogout = () => {
    localStorage.clear();
    authService.removeTwoFactorAuthToken();
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          {/** INICIO DO ASIDE */}
          {classe === "aside-on" && (
            <div class="aside-overlay" onClick={toggleCollapsed}></div>
          )}
          <div
            className={
              "aside aside-left d-flex flex-column flex-row-auto " + classe
            }
            id="kt_aside"
          >
            <div
              className="aside-menu-wrapper flex-column-fluid"
              id="kt_aside_menu_wrapper"
            >
              {/*begin::Menu Container*/}
              <div
                id="kt_aside_menu"
                className="aside-menu"
                data-menu-vertical={1}
                data-menu-scroll={1}
                data-menu-dropdown-timeout={500}
              >
                {/*begin::Menu Nav*/}
                <ul className="menu-nav">
                  <Menu
                    title="Painel de Controle"
                    Icon={PainelIcon}
                    onClick={toggleCollapsed}
                    link="/painel"
                  />
                  <MenuSection title="Operacional" />
                  <Menu
                    title="Estabelecimentos"
                    Icon={EstabelecimentoIcon}
                    onClick={() => alternaMenu("produtos")}
                    className={menus?.produtos}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("est_ger_est_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Estabelecimentos"
                      to="/estabelecimentos"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_for_pag_ia")}
                      onClick={toggleCollapsed}
                      text="Forma de Pagamento"
                      to="/estabelecimentos/formapagamento"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_ger_ava_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Avaliações"
                      to="/estabelecimentos/avaliacoes"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_ger_cup_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Cupons"
                      to="/estabelecimentos/cupons"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_ger_loc_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Locais"
                      to="/estabelecimentos/locais"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_ger_cat_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Categorias"
                      to="/estabelecimentos/categorias"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_pen_ia")}
                      onClick={toggleCollapsed}
                      text="Pendências"
                      to="/estabelecimentos/pendencias"
                    />
                    <SubMenu
                      permission={true}
                      onClick={toggleCollapsed}
                      text="Solicitações de estorno de multa"
                      to="/estabelecimentos/solicitar-estorno-multa"
                    />
                  </Menu>

                  <Menu
                    permission={!!doIHaveThisRole("est_pro_oc")}
                    title="Gerenciar Produtos EAN"
                    Icon={ConfigIcon}
                    onClick={() => {}}
                    link="/produtos"
                  />

                  <Menu
                    title="Pagamento Online"
                    Icon={PagamentoIcon}
                    onClick={() => alternaMenu("pagon")}
                    className={menus?.pagon}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("pag_banco_ia")}
                      onClick={toggleCollapsed}
                      text="Bancos"
                      to="/pagon/bancos"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("pag_band_ia")}
                      onClick={toggleCollapsed}
                      text="Bandeiras"
                      to="/pagon/bandeira"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("pag_cad_ia")}
                      onClick={toggleCollapsed}
                      text="Categorias (MCCs)"
                      to="/pagon/mccs"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("pag_tax_ia")}
                      onClick={toggleCollapsed}
                      text="Planos de taxas"
                      to="/pagon/planos"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("pag_lot_a")}
                      onClick={toggleCollapsed}
                      text="Lotes (payout)"
                      to="/pagon/report-payout"
                    />
                  </Menu>

                  <Menu
                    title="Representantes"
                    Icon={RepresentantesIcon}
                    onClick={() => alternaMenu("representantes")}
                    className={menus?.representantes}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("rep_ger_rep_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Representante"
                      to="/representantes/cadastro"
                    />
                  </Menu>

                  <Menu
                    title="Relatório"
                    Icon={RelatorioIcon}
                    onClick={() => alternaMenu("relatorios")}
                    className={menus?.relatorios}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("rel_das_a")}
                      onClick={toggleCollapsed}
                      text="Dashbord"
                      to="/dashbord"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_fat_atr_a")}
                      onClick={toggleCollapsed}
                      text="Faturas"
                      to="/relatorios/faturas"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_his_a")}
                      onClick={toggleCollapsed}
                      text="Histórico"
                      to="/relatorios/historico"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_ped_ec_a")}
                      onClick={toggleCollapsed}
                      text="Pedidos Efetuados/Cancelados"
                      to="/relatorios/pedidos"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_map_cid_a")}
                      onClick={toggleCollapsed}
                      text="Mapa por Cidade"
                      to="/relatorios/mapacidade"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_map_est_a")}
                      onClick={toggleCollapsed}
                      text="Mapa por Estabelecimento"
                      to="/relatorios/mapa"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_ven_per")}
                      onClick={toggleCollapsed}
                      text="Vendas"
                      to="/relatorios/vendaperiodo"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_ven_cid")}
                      onClick={toggleCollapsed}
                      text="Vendas Cidade/Hab."
                      to="/relatorios/vendascidade"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_ven_est")}
                      onClick={toggleCollapsed}
                      text="Vendas x est x período"
                      to="/relatorios/vendaestperiodo"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_ops_est")}
                      onClick={toggleCollapsed}
                      text="Operação lojas"
                      to="/relatorios/operacaoest"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("rel_ops_kpi")}
                      onClick={toggleCollapsed}
                      text="Operação KPIs"
                      to="/relatorios/operacaokpi"
                    />
                  </Menu>
                  <Menu
                    title="Fiscal"
                    Icon={FiscalIcon}
                    onClick={() => alternaMenu("fiscal")}
                    className={menus?.fiscal}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("fis_lis_a")}
                      onClick={toggleCollapsed}
                      text="Listar NF"
                      to="/fiscal/listaNfse"
                    />
                    <SubMenu
                      permission={true}
                      onClick={toggleCollapsed}
                      text="Ajustes financeiros"
                      to="/fiscal/ajustes-financeiros"
                    />
                  </Menu>

                  <Menu
                    title="Configurações da Loja"
                    Icon={ConfigIcon}
                    onClick={() => alternaMenu("configuracoes")}
                    className={menus?.configuracoes}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("con_imp_ie_a")}
                      onClick={toggleCollapsed}
                      text="Impressão"
                      to="/configuracoes/impressao"
                    />
                  </Menu>

                  <Menu
                    title="Marketing"
                    Icon={MarketingIcon}
                    onClick={() => alternaMenu("marketing")}
                    className={menus?.marketing}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("admin_banner")}
                      onClick={toggleCollapsed}
                      text="Gerenciar banners"
                      to="/marketing/BannerList"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("mar_pus_a")}
                      onClick={toggleCollapsed}
                      text="Enviar push cidade"
                      to="/marketing/push"
                    />
                  </Menu>

                  <Menu
                    title="Usuários"
                    Icon={UsuariosIcon}
                    onClick={() => alternaMenu("usuarios")}
                    className={menus?.usuarios}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("usu_usu_ia")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Usuários"
                      to="/usuarios/usuarios"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("usu_per_a")}
                      onClick={toggleCollapsed}
                      text="Usuários/Permissão ADM"
                      to="/configuracoes/usuarios"
                    />
                  </Menu>

                  <Menu
                    title="Pedidos"
                    Icon={PedidosIcon}
                    onClick={() => alternaMenu("pedidos")}
                    className={menus?.pedidos}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("ped_ped_a")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Pedidos Global"
                      to="/pedidos"
                    />
                  </Menu>

                  <Menu
                    title="Tickets"
                    Icon={TicketsIcon}
                    onClick={() => alternaMenu("tickets")}
                    className={menus?.tickets}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("tic_ger_a")}
                      onClick={toggleCollapsed}
                      text="Gerenciar Tickets"
                      to="/contatos/tickets"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("est_lis_sug_ia")}
                      onClick={toggleCollapsed}
                      text="Listar Sugestões"
                      to="/estabelecimentos/sugestoes"
                    />
                  </Menu>

                  <Menu
                    title="Ferramentas"
                    Icon={FerramentasIcon}
                    onClick={() => alternaMenu("ferramentas")}
                    className={menus?.ferramentas}
                  >
                    <SubMenu
                      permission={doIHaveThisRole("fer_art_a")}
                      onClick={toggleCollapsed}
                      text="Artes"
                      to="/ferramentas/artes"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("fer_atu_com_a")}
                      onClick={toggleCollapsed}
                      text="Atualizar Comissão"
                      to="/ferramentas/atualizacomissao"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("fer_pro_fra_a")}
                      onClick={toggleCollapsed}
                      text="Procurar Fraudes"
                      to="/ferramentas/fraudes"
                    />
                    <SubMenu
                      permission={doIHaveThisRole("fer_retpagonline_a")}
                      onClick={toggleCollapsed}
                      text="Retirar Pag. Online"
                      to="/ferramentas/ret_pag_online"
                    />
                  </Menu>

                  <Menu
                    title="Logout"
                    className="mt-10"
                    Icon={LogoutIcon}
                    onClick={() => {
                      doLogout();
                      toggleCollapsed();
                    }}
                    link="/login"
                  />
                </ul>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header onClick={toggleCollapsed} storeDetails={getDetalhesLoja} />
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
