import axios from "axios";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import AxiosLogger from "../axiosLogger";

import { authService } from "../authService";

import withReactContent from "sweetalert2-react-content";
const SwalRC = withReactContent(Swal);

const apiLaravelV2 = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL_V2_LARAVEL,
  headers: {
    Authorization: localStorage.getItem("@ADM-Aiboo/token_session") ?? null,
    "x-2fa-token": secureLocalStorage.getItem("@ADM-Aiboo/2fa-token") ?? null,
    "x-user-ip": localStorage.getItem("@ADM-Aiboo/user-ip") ?? null,
  },
});

AxiosLogger(apiLaravelV2, localStorage.getItem("@ADM-Aiboo/hash"));


apiLaravelV2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    authService.removeAuthenticationToken();
    authService.removeTwoFactorAuthToken();
    localStorage.clear();

    SwalRC.fire({
      title: "Sua sessão é inválida.",
      html: "É necessário fazer o login novamente.",
      footer: (
        <a className="btn btn-primary btn-sm" href="index.html">
          Fazer Login
        </a>
      ),
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    return Promise.reject(error);
  }
);

export default apiLaravelV2;
