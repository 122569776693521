import styled from "styled-components";

export const TruncatedText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    transition: opacity 0.2s linear;
    opacity: 0.8;
  }
`;

export const ExpandedText = styled.div`
  cursor: pointer;
`;
