import { create } from "zustand";
import apiV2 from "services/api-laravelv2";

export const useFineRefundStore = create()((set, get) => ({
  loading: false,
  status: "pending",
  error: "",
  data: [],
  per_page: 10,
  current_page: 1,
  last_page: 0,
  total: 0,
  merchant_id: null,
  order_hash: null,
  setMerchantId: (merchant_id) => {
    set({ merchant_id });
  },
  setOrderHash: (order_hash) => {
    set({ order_hash });
  },
  setCurrentPage: (page) => {
    set({ current_page: page });
    get().fineRefundRequest();
  },
  handleSearch: () => {
    set({ current_page: 1 });
    get().fineRefundRequest();
  },
  fineRefundRequest: async () => {
    set({ loading: true });
    const { per_page, current_page, merchant_id, order_hash } = get();
    try {
      const params = {
        page: current_page,
        per_page,
      };

      merchant_id && (params.merchant_id = merchant_id);
      order_hash && (params.order_hash = order_hash);

      const resp = await apiV2.get(`/finance/reversal-fine`, {
        params,
      });
      const { req_reversal, qty_pages, total } = resp.data;
      set({ data: req_reversal, total, last_page: qty_pages });

      return true;
    } catch (err) {
      return false;
    } finally {
      set({ loading: false });
    }
  },
  handleFineRefundAnalysis: async (id, is_approved) => {
    try {
      await apiV2.put(`/finance/reversal-fine/analyze/${id}`, {
        is_approved,
      });
      get().fineRefundRequest();
      return true;
    } catch (err) {
      return false;
    }
  },
}));
