import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito';

  ${props => props.size && `width: ${props.size};`}

  label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }

  input {
    border-radius: 12px;
  }
`;

export const SearchBox = styled.div`
  position: absolute;
  top: 70px;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito';
  width: 100%;
  overflow-y: auto;
  max-height: 250px;
  background-color: #fff;
  border: 1px solid #DCDCDC;
  border-radius: 12px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  padding: 8px 0px;
  z-index: 99;

  ${props => props.hide && `display: none;`}

  > div {
    line-height: 30px;
    background-color: #fff;
    padding: 0 24px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;