import apiV2 from "services/api-laravelv2";

const getEstablishmentContractInfo = async ({ merchantId }) => {
  const response = await apiV2.get(`merchants/contract/signers/${merchantId}`);

  return response.data;
};

const getEstablishmentContractLogs = async ({ merchantId }) => {
  const response = await apiV2.get("merchants/contract/log", {
    params: { merchant_id: Number(merchantId) },
  });

  return response.data;
};

const generateEstablishmentContract = async ({ merchantId }) => {
  const response = await apiV2.post("merchants/contract", {
    merchant_id: Number(merchantId),
  });

  return response.data;
};

const deleteEstablishmentContract = async ({ merchantId }) => {
  const response = await apiV2.delete(`merchants/contract/${merchantId}`);

  return response.data;
};

const showEstablishmentContract = async ({ merchantId }) => {
  const response = await apiV2.get(`merchants/contract/${merchantId}`);

  return response.data;
};

export const contractService = {
  getEstablishmentContractInfo,
  getEstablishmentContractLogs,
  generateEstablishmentContract,
  deleteEstablishmentContract,
  showEstablishmentContract,
};
