import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  display: flex;
  align-items: center;

  color: #333333;
`;

export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  align-items: center;

  color: #333333;
`;
