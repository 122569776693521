import styled from "styled-components";

export const TroubleInListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
`;

export const TroubleInListContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4.5rem;

  img {
    width: 20rem;
    height: auto;
  }
`;

export const TroubleInListMessageTextWrapper = styled.div`
  text-align: center;
`;
