import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  text-align: left;
  th {
    font-weight: 500;
    color: #333333;
  }
`;

export const TableCell = styled.td`
  height: 60px;
  border-top: 1px solid #e8ecf0;
  padding: 0.75rem 0.75rem 0.75rem 0;
  max-width: ${({ maxWidth }) => `${maxWidth}rem`};
  color: ${({ positiveValue, adjustmentValueCell }) =>
    !adjustmentValueCell ? "#333333" : positiveValue ? "#00b333" : "#ff3333"};
`;
