import React from "react";

export function Logo() {
  return (
    <a href="index.html" className="ml-10">
      <img
        alt="Logo"
        src="assets/media/logos/aiboo.png"
        className="logo-sticky max-h-45px"
      />
    </a>
  );
}
