import React from "react";

import * as Styled from "./styles";

export const TabButtons = ({ onChange, value }) => {
  return (
    <Styled.Container>
      <Styled.Label>
        Tipo
        <span>*</span>
      </Styled.Label>

      <Styled.ButtonsContainer>
        <Styled.Button
          type="button"
          isSelected={value === "remove"}
          style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
          onClick={() => onChange("type", "remove")}
        >
          <span>Desconto</span>
        </Styled.Button>

        <Styled.Button
          type="button"
          style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
          isSelected={value === "add"}
          onClick={() => onChange("type", "add")}
        >
          <span>Acréscimo</span>
        </Styled.Button>
      </Styled.ButtonsContainer>
    </Styled.Container>
  );
};
