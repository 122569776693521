import styled from 'styled-components';

export const Container = styled.div`
  font-size: 14px;
  font-family: 'Nunito';
  overflow-y: auto;

  td {
    vertical-align: middle;
    height: 62px;

    &.center {
      text-align: center;

      & > div {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const TransferButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid #DCDCDC;
  padding: 4px 9px 4px 9px;
  border-radius: 4px;
  gap: 12px;

  font-weight: 400;
`;
